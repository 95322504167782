<template>
  <TabContent :loading="storyHasLoaded" :errorOccurred="errorOccurred">
    <section class="contents" ref="contents">
      <h1>{{ title }}</h1>
      <p class="attribution">By {{ formattedParticipantsList }}</p>
      <section class="body">
        <span class="contribution" v-for="entry in contents" :key="entry.number" :ref="setEntryRef">
          <span class="submission" :class="{ image: entry.image }" tabIndex="0">
            <span v-if="entry.submission" class="text">{{ entry.submission }}&nbsp;&nbsp;</span>
            <img v-else alt="Story illustration" :src="getImageSrc(entry.image)" />
            <span class="metadata"
              >This was contributed by <span class="contributed-by">{{ entry.username }}</span
              >.
              <span v-if="entry.challengeText">Their challenge was: {{ entry.challengeText }}</span>
            </span>
          </span>
        </span>
        <MysticalButton
          label="Download PDF"
          @click="getPDF()"
          :disabled="generatingPDF"
          class="pdf-button"
        />
      </section>
    </section>
  </TabContent>
</template>

<script>
import { jsPDF as JsPDF } from 'jspdf';
import TabContent from '../components/TabContent.vue';
import MysticalButton from '../components/MysticalButton.vue';
import { getStoryContents, getFormattedParticipantsList } from '../services/storiesService';

export default {
  name: 'CompletedStory',
  components: {
    TabContent,
    MysticalButton,
  },
  data() {
    return {
      storyId: null,
      contents: null,
      title: '',
      errorOccurred: false,
      participants: [],
      generatingPDF: false,
      entryRefs: [],
    };
  },
  computed: {
    storyHasLoaded() {
      return this.contents === null;
    },
    formattedParticipantsList() {
      return getFormattedParticipantsList(this.participants);
    }
  },
  beforeUpdate() {
    this.entryRefs = [];
  },
  updated() {
    const isLargeDevice = window.matchMedia('(min-width: 700px').matches;

    if (isLargeDevice) {
      this.adjustMetaDataElsPositioning();
    }
  },
  methods: {
    getImageSrc(imageData) {
      return `data:image/png;base64,${imageData}`;
    },
    getSortedRounds(rounds) {
      return rounds.sort((a, b) => {
        return a.number > b.number ? 1 : -1;
      });
    },
    removeSpecialTextMarkers(rounds) {
      return rounds.map(r => {
        return { ...r, challengeText: r.challengeText?.replaceAll('*', '') };
      });
    },
    getPDF() {
      this.generatingPDF = true;

      const doc = new JsPDF();
      const pdfHTML = document.createElement('div');

      const titleElement = document.createElement('h1');
      const participantsElement = document.createElement('div');

      titleElement.innerText = this.title;
      titleElement.style.color = 'black';
      titleElement.style.textAlign = 'center';
      participantsElement.innerText = `By: ${this.formattedParticipantsList}`;
      participantsElement.style.color = 'black';
      participantsElement.style.textAlign = 'center';
      participantsElement.style.marginBottom = '20px';

      pdfHTML.appendChild(titleElement);
      pdfHTML.appendChild(participantsElement);
      pdfHTML.style.fontSize = '10px';
      pdfHTML.style.width = '300px';
      pdfHTML.style.margin = '40px';

      this.contents.forEach(entry => {
        if (entry.image) {
          const el = document.createElement('img');
          el.src = this.getImageSrc(entry.image);
          el.style.width = '200px';
          el.style.height = '200px';
          pdfHTML.appendChild(el);
        } else {
          const el = document.createElement('span');
          el.innerHTML = `${entry.submission}&nbsp;`;
          el.style.color = 'black';
          el.style.fontSize = '10px';
          pdfHTML.appendChild(el);
        }
      });

      doc
        .html(pdfHTML, {
          callback: function() {
            doc.save();
          },
          html2canvas: {
            scale: 0.5,
            width: 1200,
          },
        })
        .then(() => {
          this.generatingPDF = false;
        });
    },
    loadStory(storyId) {
      this.errorOccurred = false;
      this.storyId = storyId;

      getStoryContents(this.storyId)
        .then(contents => {
          const sortedRounds = this.getSortedRounds(contents.rounds);
          const sortedRoundsWithMarkersRemoved = this.removeSpecialTextMarkers(sortedRounds);
          this.contents = sortedRoundsWithMarkersRemoved;
          this.title = contents.title;
          this.participants = contents.participants;
        })
        .catch(() => {
          this.errorOccurred = true;
        });
    },
    setEntryRef(el) {
      if (el) {
        this.entryRefs.push(el);
      }
    },
    adjustMetaDataElsPositioning() {
      // On larger devices, it's possible for the metadata popups to go off the screen if it's wide
      // and positioned near the right edge of the story. So, we check if it goes past the bounding edge
      // of the section and, if so, move it to the left so it's fully visible.
      const sectionRightEdge = this.$refs.contents.getBoundingClientRect().right;

      this.entryRefs.forEach(entry => {
        const metadata = entry.querySelector('.metadata');
        const metaDataRightEdge = metadata.getBoundingClientRect().right;

        if (metaDataRightEdge > sectionRightEdge) {
          metadata.style.left = `${sectionRightEdge - metaDataRightEdge}px`;
        }
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.loadStory(to.params.storyId);
    next();
  },
  mounted() {
    this.loadStory(this.$route.params.storyId);
  },
};
</script>

<style lang="scss" scoped>
.contents {
  color: var(--color-graphite);
  background: var(--color-purple-lighten-3);
  padding: 8rem 0 10rem 0;

  .attribution {
    text-align: center;
  }

  .submission,
  .attribution {
    font-size: 2rem;
  }

  .mystical-button {
    margin: 10rem auto 0 auto;
  }

  .body {
    margin: 0 15vw;
    text-align: justify;

    .submission {
      position: relative;
      transition: background 600ms ease;
      padding: 0.2rem;
      cursor: pointer;
      outline: none;

      &.image {
        display: flex;
      }

      img {
        margin-left: auto;
        margin-right: auto;
      }

      .metadata {
        display: block;
        position: absolute;
        opacity: 0;
        left: 0;
        bottom: 4rem;
        pointer-events: none;
        transform: translateY(2rem);
        transition: opacity 600ms ease, transform 600ms ease;
        background: var(--color-graphite-lighten);
        color: var(--color-white);
        padding: 1rem;
        border-radius: 0.5rem;
        width: 40rem;
        z-index: var(--z-story-sentence-metadata);

        .contributed-by {
          font-weight: 800;
          color: var(--color-creamsicle);
        }
      }

      &:hover,
      &:focus,
      &:active {
        .text {
          background: var(--color-creamsicle);
        }

        img {
          outline: 2px solid var(--color-creamsicle);
          outline-style: dashed;
        }

        .metadata {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .pdf-button {
      justify-content: center;
      margin-top: 5rem;
    }
  }
}

@media (max-width: 845px) {
  .contents .body {
    margin: 0 10vw;
  }

  .contents .body .submission .text {
    font-size: 1.5rem;
  }

  .contents .body .submission img {
    width: 300px;
  }
}

@media (max-width: 700px) {
  .contents .body .submission .metadata {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    top: unset;
    padding-bottom: 2rem;
  }
}

@media (max-width: 600px) {
  .contents .body .submission img {
    width: 200px;
  }
}

@media (max-width: 550px) {
  .contents .body {
    margin: 0 5vw;
  }
  .contents .body .submission .text {
    font-size: 1.3rem;
  }
}

@media (max-width: 500px) {
  .contents .body .submission img {
    width: 150px;
  }
}
</style>
